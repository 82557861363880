import React from "react";
import "./infoSchema.css";
import { Row, Col } from "reactstrap";

const InfoSchema = () => {
  return (
    <div className="pageContainer infoSchemaContainer" id="om">
      <div className="bgImgSchedule"></div>

      <Col className="col-12">
        <h1 className="headingSecondary">OM STOCKHOLM SOMMELIERKLASS</h1>
      </Col>

      <Row className="w-100 infoContentRow">
        <Col className="col-md-6 col-12 infoContentCol">
          <p>
            Stockholm Sommelierklass AB ägs och drivs av <b>Gustav Kock</b>,
            examinerad
            <b> Internationell Sommelier</b> från <b>Restaurangakademien</b> i
            Stockholm. Som Sommelier har Gustav tidigare arbetat på vinbaren
            tillhörande ICA Medborgarplatsen där han var ansvarig för vinlistan
            och inköp av vin. Vidare reste Gustav till Koh Lanta i Thailand för
            att driva en av resorten, Slow Down:s barer tillsammans med sin
            äldsta vän. I samband med hemkomsten blev Gustav{" "}
            <b>Sommelierchef</b> på Restaurang B.A.R på Blasieholmen i
            Stockholm. Där arbetade Gustav till och med oktober 2019 då han fick
            sin nuvarande sommeliertjänst på
            <b> Vinkällaren Grappe</b>. Nu arbetar Gustav på Vinkällaren Grappe
            och driver Stockholm Sommelierklass vid sidan om. Sommelierklass
            erbjuder
            <b> vinprovningar</b> för <b>företag och privatpersoner</b> samt
            <b> konsultuppdrag</b>.
            <br />
            <br />
            <span id="contentSpan">
              <b>
                Vinprovningarna anpassas efter dina önskemål och en offert
                skickas därefter. Förberedelser och vinprovningen kostar 3500 kr
                ex. moms. Kostnad för vin och eventuella övriga kostnader
                tillkommer.
              </b>
              <br />
              <br />
              <b>
                Konsultuppdrag kan exempelvis handla om att få hjälp med
                vinpaket till en middag, fest eller ett bröllop.
                Rekommendationer gällande lagring av vin eller vägledning vid
                inköp av vin som investering.
              </b>
              <br />
              <b>Pris för konsultuppdrag: 750 kr inkl. moms/timme</b>
            </span>
          </p>
        </Col>
        <Col className="col-md-4 col-12 infoContentCol">
          <img src="kocken2.jpg" alt="..." />
        </Col>
      </Row>
    </div>
  );
};

export default InfoSchema;
