import { useState } from "react";
import "./home.css";
import {
  Row,
  Col,
  Container,
} from "reactstrap";

// import emailjs from "emailjs-com";
// import { init } from "emailjs-com";
// init("user_YnkXPlVwLYoYY1aere2EN");

const Home = () => {
  // const [message, setMessage] = useState(false);
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setMessage(true);
  //   emailjs
  //     .sendForm(
  //       "service_j07rk7q",
  //       "template_o3qelcs",
  //       e.target,
  //       "user_YnkXPlVwLYoYY1aere2EN"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  //   e.target.reset();
  //   setTimeout(() => setMessage(false), 5000);
  // };

  return (
    <div className="homeContainer">
      <div className="bgImgHome"></div>

      <Row className="w-100 mt-5">
        <Col className="col-md-7 col-12 homeContentCol">
          <Container className="headingContainer">
            <Row>
              <Col className="col-md-12 col-6">
                <img id="logoImg" src="vinGlasLogga.svg" alt="..." />
              </Col>
              <Col className="logoTextDesc col-md-12">
                <h2>Vinprovning | Vinkonsultation</h2>
              </Col>
            </Row>

            <h1 id="logoTextMain">Sommelier</h1>
            <h1 id="logoTextSecond">Klass</h1>
          </Container>
        </Col>

        <Col className="col-md-4 col-12 homeContentCol">
          <Container className="formContainer">
            <Col className="col-12">
              <h4>Vill du boka en provning?</h4>
            </Col>
            <Col className="col-12">
              <p>
                Maila en intresseanmälan till <br />{" "}
                <span>stockholmsommelierklass@outlook.com</span>
              </p>
            </Col>

            {/* <Row>
              <Col className="col-12">
                <h4>BOKA PROVNING</h4>
              </Col>
              <Col className="col-12">
                <p>(intresseanmälan)</p>
              </Col>
              <Col className="col-12">
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Namn"
                      type="name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="email"
                      name="email"
                      placeholder="E-post"
                      type="email"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="message"
                      name="message"
                      type="textarea"
                      placeholder="Meddelande"
                    />
                  </FormGroup>
                  <Row>
                    <Col className="btnCol">
                      {message ? (
                        <span>Tack för ditt mail!</span>
                      ) : (
                        <Button color="success">Skicka!</Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row> */}
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
