import "./juniorturnering.css";
import { Row, Col } from "reactstrap";

const JuniorTurnering = () => {
  return (
    <div id="intervju" className="pageContainer juniorContainer">
      <Row className="w-100 align">
        <Col className="col-12 mb-md-5">
          <h1 className="headingSecondary">INTERVJU</h1>
        </Col>
        <Col className="col-4">
          <Row className="interviewTextRow">
            <Col className="interviewTextCol greenBg">
              <p>
                <b>Vem är Gustav Kock?</b> <br />
                "Jag är en glad och energirik 27-åring. Jag älskar äventyr och
                min tid spenderar jag på vin och matupplevelser, resor, vänner
                och med familjen. Jag är uppvuxen i Bromma i Stockholm och bor
                just nu i min lägenhet på Gärdet. "
              </p>
            </Col>
            <Col className="interviewTextCol redBg">
              <p>
                <b>Vad dricker du helst?</b> <br />"Skulle jag bara dricka vin
                från ett land resten av livet hade det varit från Frankrike, men
                jag dricker gärna allt så länge det är av god kvalitet och
                passar till det jag äter."
              </p>
            </Col>
            <Col className="interviewTextCol yellowBg">
              <p>
                <b>Din bästa vinupplevelse?</b> <br />" Det måste vara när jag
                var 18 år och fick prova ett fint vin som pappa fått i present.
                Jag minns hur jag provade vinet och först tänkte ”jaha, rött
                vin”. När jag sedan tog en bit av oxfilén och provade vinet igen
                var det en helt ny upplevelse, en smakexplosion och jag var
                fast"
              </p>
            </Col>
          </Row>
        </Col>
        <Col className="col-4">
          {" "}
          <img id="intervjuImg" src="intervju.jpg" alt="" />{" "}
        </Col>
        <Col className="col-4">
          <Row className="interviewTextRow">
            <Col className="interviewTextCol blueBg">
              <p>
                <b>Vad gör du som sommelier på Vinkällaren Grappe?</b> <br />"
                Att arbeta en tid på Vinkällaren Grappe kan vara den bästa
                skolan en sommelier kan få. Mitt största ansvarsområde är
                medlemsklubbsrummet där medlemmarna avnjuter sina egna lagrade
                viner med service från Grappes sommelierer. Som sommelier på
                Grappe provar jag av alla viner och dekanterar de viner som
                behöver tid på karaff. Utöver klubbrummet håller jag
                vinprovningar, ger vinrekommendationer till medlemmarna och
                serverar under allt från affärsmöten till finare privata
                tillställningar med vita dukar och silver på borden."
              </p>
            </Col>
            <Col className="interviewTextCol greenBg">
              <p>
                <b>Varför startade du Sommelierklass?</b> <br />" I och med att
                jag fått så mycket förfrågningar att hålla provningar vid
                privata tillställningar och företagsevent bestämde jag mig för
                att starta eget bolag med vinprovningar som huvudfokus. Jag
                älskar att prata inför folk, att sprida vinglädje, inspirera
                och lära ut. "
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default JuniorTurnering;
