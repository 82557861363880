import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
//Router
import { BrowserRouter as Router } from "react-router-dom";
//Components
import NavbarMenu from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import InfoSchema from "./pages/infoSchema/InfoSchema";
import Provningar from "./pages/provningar/Provningar";
import JuniorTurnering from "./pages/juniorturnering/JuniorTurnering";
import Footer from "./components/footer/Footer";

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpointMedium = 768;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [width]);

  return (
    <Router>
      <div className="App" id="hem">
        <NavbarMenu width={width} breakpointMedium={breakpointMedium} />
        <Home />
        <InfoSchema />

        <Provningar />

        <JuniorTurnering />

        <Footer width={width} breakpointMedium={breakpointMedium} />
      </div>
    </Router>
  );
}

export default App;
