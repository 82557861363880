import React, { useState } from "react";
import "./navbar.css";
import { SiInstagram, SiLinkedin } from "react-icons/si";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
} from "reactstrap";
import { HashLink as Link } from "react-router-hash-link";

const NavbarMenu = ({ width, breakpointMedium }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar className="navbarColor" fixed="top" light expand="md">
      <Row className="menuRow">
        {/* LOGO-------- */}
        <Col className="col-md-1 offset-md-1 logoMain">
          <NavbarBrand href="/">
            <Link to="#hem" smooth>
              <img className="logoImage" src="logo.svg" alt="..." />
            </Link>
          </NavbarBrand>
        </Col>

        {width < breakpointMedium ? (
          <Col className="col-6 navIconsMedium">
            <a
              href="https://www.instagram.com/sommelierklass/"
              target="_blank"
              rel="noreferrer"
            >
              <SiInstagram className="icon m-2" />
            </a>
            <a
              href="https://www.linkedin.com/in/gustav-kock-5164781a9/"
              target="_blank"
              rel="noreferrer"
            >
              <SiLinkedin className="icon m-2" />
            </a>
          </Col>
        ) : (
          ""
        )}
        <Col
          className={
            width > breakpointMedium
              ? "col-6 menuItems offset-md-2"
              : "col-3 menuItemsSmall"
          }
        >
          <Row>
            <Col className="col-12">
              <NavbarToggler onClick={toggle} />
            </Col>
            <Col className="col-12">
              <Collapse isOpen={isOpen} navbar>
                <Nav navbar>
                  <NavItem>
                    <NavLink href="/">
                      <Link to="#hem" smooth>
                        Hem
                      </Link>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/om">
                      <Link to="#om">Om Oss</Link>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/provningar">
                      <Link to="#provningar">Vinprovningar</Link>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/intervju">
                      <Link to="#intervju">Intervju</Link>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="/contact">
                      <Link to="#contact">Kontakt</Link>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Col>
          </Row>
        </Col>
        {width > breakpointMedium ? (
          <Col className="col-2 offset-md-1 iconItems">
            <a
              href="https://www.instagram.com/sommelierklass/"
              target="_blank"
              rel="noreferrer"
            >
              <SiInstagram className="icon m-2" />
            </a>
            <a
              href="https://www.linkedin.com/in/gustav-kock-5164781a9/"
              target="_blank"
              rel="noreferrer"
            >
              <SiLinkedin className="icon m-2" />
            </a>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Navbar>
  );
};

export default NavbarMenu;
