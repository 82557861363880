import "./provningar.css";
import { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { IoIosArrowDown } from "react-icons/io";

const Provningar = () => {
  const [listObject, setListObject] = useState({
    activeObject: null,
    objects: [
      { id: 1, showList: false },
      { id: 2, showList: false },
      { id: 3, showList: false },
      { id: 4, showList: false },
    ],
  });

  const toggleActive = (index) => {
    setListObject({ ...listObject, activeObject: listObject.objects[index] });
  };

  const toggleClassName = (index) => {
    if (listObject.objects[index] === listObject.activeObject) {
      return "showList";
    } else {
      return "hideList";
    }
  };

  return (
    <>
      <div id="provningar" className="pageContainer provningarContainer">
        <Row className="w-100 align ">
          <Col className="col-12">
            <h1 className="headingSecondary provningHeading">PROVNINGAR</h1>
          </Col>
          {/* <img className="splashImg" src="splashWine.png" alt="" /> */}

          <Row className="my-md-5">
            {listObject.objects.map((elements, index) => {
              //FIRST ROW-------------------------------------------------------------------------------
              if (elements.id < 3) {
                return (
                  <Col key={index} className="col-md-6 col-12 provningarContentCol">
                    <Row>
                      <Col className="col-3"></Col>
                      <Col className="offset-1 col-4">
                        <h3>
                          {elements.id === 1 ? "Fördjupningar" : "Vinländer"}
                        </h3>
                      </Col>
                      <Col className="col-3 arrowCol">
                        <IoIosArrowDown />
                      </Col>
                    </Row>

                    <Container
                      className="provningContainer"
                      onClick={() => {
                        toggleActive(index);
                      }}
                    >
                      <div className="listBehind">
                        <Row>
                          {elements.id === 1 ? (
                            //Top Row, First Rows
                            <>
                              <Col className="col-6">Champagne</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                          ) : (
                            <>
                              <Col className="col-3 flagCol">
                                <img
                                  className="flag"
                                  src="italy.png"
                                  alt="..."
                                />
                              </Col>
                              <Col className="col-9">Italien</Col>
                            </>
                            //-------------------
                          )}
                        </Row>
                        <Row>
                          {elements.id === 1 ? (
                            //Top Row, Second Rows
                            <>
                              <Col className="col-6">Bourgougne</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                          ) : (
                            <>
                              <Col className="col-3 flagCol">
                                <img
                                  className="flag"
                                  src="spain.png"
                                  alt="..."
                                />
                              </Col>
                              <Col className="col-9">Spanien</Col>
                            </>
                            //-------------------
                          )}
                        </Row>
                        <Row>
                          {elements.id === 1 ? (
                            //Top Row, Third Rows (1&2)
                            <>
                              <Col className="col-6">Bordeaux</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                          ) : (
                            <>
                              <Col className="col-3 flagCol">
                                <img
                                  className="flagFrance"
                                  src="france.jpg"
                                  alt="..."
                                />
                              </Col>
                              <Col className="col-9">Frankrike</Col>
                            </>
                            //-------------------
                          )}
                        </Row>
                        <Row>
                          {elements.id === 1 ? (
                            //Top Row, First Rows (1&2)
                            <>
                              <Col className="col-6">Chablis</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                          ) : (
                            <>
                              <Col className="col-3 flagCol">
                                <img
                                  className="flag"
                                  src="germany.png"
                                  alt="..."
                                />
                              </Col>
                              <Col className="col-9">Tyskland</Col>
                            </>
                            //-------------------
                          )}
                        </Row>
                      </div>
                      <div
                        id={elements.id === 1 ? "fordjupning" : "vinlander"}
                        className={toggleClassName(index)}
                      >
                        <h5>Klicka För Lista!</h5>
                      </div>
                    </Container>
                  </Col>
                );
              }
            })}
          </Row>

          <Row className="my-md-5">
            {listObject.objects.map((elements, index) => {
              //2nd ROW-------------------------------------------------------------------------------
              if (elements.id > 2) {
                return (
                  <Col key={index} className="col-md-6 col-12 provningarContentCol">
                    <Row>
                      <Col className="col-3"></Col>
                      <Col className="offset-1 col-4">
                        <h3>{elements.id === 3 ? "Druvor" : "Introduktion"}</h3>
                      </Col>
                      <Col className="col-3 arrowCol">
                        <IoIosArrowDown />
                      </Col>
                    </Row>

                    <Container
                      className="provningContainer"
                      onClick={() => {
                        toggleActive(index);
                      }}
                    >
                      <div className="listBehind">
                        <Row>
                          {elements.id === 3 ? (
                            //Top Row, First Rows
                            <>
                              <Col className="col-6">Cabernet Sauvignon</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                          ) : (
                            <>
                              <Col className="col-6">
                                Gustavs Vinprovarskola
                              </Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                            //-------------------
                          )}
                        </Row>
                        <Row>
                          {elements.id === 3 ? (
                            //Top Row, Second Rows
                            <>
                              <Col className="col-6">Pinot Noir</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                          ) : (
                            <>
                              <Col className="col-6">Mousserande Vin</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                            //-------------------
                          )}
                        </Row>
                        <Row>
                          {elements.id === 3 ? (
                            //Top Row, Third Rows (1&2)
                            <>
                              <Col className="col-6">Riesling</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                          ) : (
                            <>
                              <Col className="col-6">Kalifornien</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                            //-------------------
                          )}
                        </Row>
                        <Row>
                          {elements.id === 3 ? (
                            //Top Row, First Rows (1&2)
                            <>
                              <Col className="col-6">Chardonnay</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                          ) : (
                            <>
                              <Col className="col-6">Rosévin</Col>
                              <Col className="col-6">60-90 min</Col>
                            </>
                            //-------------------
                          )}
                        </Row>
                      </div>
                      <div
                        id={elements.id === 3 ? "druvor" : "introduktion"}
                        className={toggleClassName(index)}
                      >
                        <h5>Klicka För Lista!</h5>
                      </div>
                    </Container>
                  </Col>
                );
              }
            })}
          </Row>
        </Row>
      </div>
    </>
  );
};

export default Provningar;
