import "./footer.css";
import { Row, Col } from "react-bootstrap";
import { SiInstagram, SiLinkedin } from "react-icons/si";
import { FiMail, FiPhone } from "react-icons/fi";
import { GiPostOffice } from "react-icons/gi";

const Footer = ({ width, breakpointMedium }) => {
  return (
    <div id="contact" className="pageContainer footerContainer">
      <Row className="align w-100">
        <Col className="col-lg-4 col-md-6 col-12 offset-lg-0 white">
          <h4 className="mb-3 offset-lg-1">Kontakt och adress</h4>

          <Row className="mb-2">
            <Col className="col-1 offset-lg-1 iconCol">
              <FiMail />
            </Col>
            <Col>
              <p>E-mail:</p>
            </Col>
            <Col className="col-8 descCol">
              <p>stockholmsommelierklass@outlook.com</p>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col className="col-1 offset-lg-1 iconCol">
              <FiPhone />
            </Col>
            <Col>
              <p>Telefon:</p>
            </Col>
            <Col className="col-8 descCol">
              <p>+46(0)722068313</p>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col className="col-1 offset-lg-1 iconCol">
              <GiPostOffice />
            </Col>
            <Col>
              <p>Postadress:</p>
            </Col>
            <Col className="col-8 descCol">
              <p>Smedsbacksgatan 28, 115 39, Stockholm</p>
            </Col>
          </Row>
        </Col>

        <Col className="col-lg-4 col-md-6 col-12">
          <Row className="my-4">
            <Col className="col-2 offset-4">
              <a
                href="https://www.instagram.com/sommelierklass/"
                target="_blank"
                rel="noreferrer"
              >
                <SiInstagram className="icon" />
              </a>
            </Col>
            <Col className="col-2">
              <a
                href="https://www.linkedin.com/in/gustav-kock-5164781a9/"
                target="_blank"
                rel="noreferrer"
              >
                <SiLinkedin className="icon" />
              </a>
            </Col>
          </Row>
        </Col>

        <Col className="col-lg-4 p-2">
          <img id="footerLogo" src="logo.svg" alt="" />
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
